<template>
  <div>
    <div class="page-title">
      <h3>New Form</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li><a href="#">Forms</a></li>
          <li class="active">
            {{ productForm ? productForm.name : 'New' }} Form
          </li>
        </ol>
      </div>
    </div>
    <div
      v-if="allowProductFormSelection"
      class="col-sm-2"
      style="padding-left: 20px; padding-top: 20px"
    >
      <label class="has-float-label">
        <label class="has-float-label">
          <VueMultiselect
            v-model="productForm"
            :allow-empty="false"
            :class="{ 'placeholder-shown': (!productForm || productForm === '') }"
            :close-on-select="true"
            :multiple="false"
            :options="_getProducts"
            :placeholder="null"
            :show-labels="false"
            label="name"
            track-by="id"
          />
          <span class="float-label">Select product form:</span>
        </label>
      </label>
    </div>
    <router-view />
  </div>
</template>

<script>

import VueMultiselect from "vue-multiselect";
import {mapGetters} from "vuex";

export default {
  name: "NewForm",
  components: {
    VueMultiselect
  },
  data() {
    return {
      allowProductFormSelection: true,
      productForm: null
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getProducts"
    ]),
    _getProducts: function () {
      return _.sortBy(_.filter(this.getProducts, (product) => {
        // For now only allow submitting RBL, HDFC, and YES Bank forms
        if (_.includes(['c03143c4-bcfb-4526-a86f-ca29fc402b78', '597c5c1a-bc21-47c9-a69d-69a4c68f73ab', '2a32cec2-1d11-48a6-84aa-9a8c9fd33c12'], product.id)) {
          if (this.getLoggedInUser.products && this.getLoggedInUser.products.length > 0) {
            return _.includes(this.getLoggedInUser.products, product.id);
          } else {
            return true;
          }
        }
      }), 'name');
    }
  },
  watch: {
    productForm: function (newProduct, oldProduct) {
      if (newProduct !== null && (oldProduct === null || newProduct.id !== oldProduct.id)) {
        switch (newProduct.id) {
          case 'c03143c4-bcfb-4526-a86f-ca29fc402b78':
            if (this.$route.name !== 'rbl') {
              this.$router.push({
                name: 'rbl',
                params: {
                  leadId: this.$route.params.id,
                  mobileNumber: this.$route.params.mobileNumber,
                  expectedVersion: this.$route.params.version
                }
              });
            }
            break;
          case '597c5c1a-bc21-47c9-a69d-69a4c68f73ab':
            if (this.$route.name !== 'hdfc') {
              this.$router.push({
                name: 'hdfc',
                params: {
                  leadId: this.$route.params.id,
                  mobileNumber: this.$route.params.mobileNumber,
                  expectedVersion: this.$route.params.version
                }
              });
            }
            break;
          case '2a32cec2-1d11-48a6-84aa-9a8c9fd33c12':
            if (this.$route.name !== 'yes') {
              this.$router.push({
                name: 'yes',
                params: {
                  leadId: this.$route.params.id,
                  mobileNumber: this.$route.params.mobileNumber,
                  expectedVersion: this.$route.params.version
                }
              });
            }
            break;
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.productId && this.$route.params.productId !== '') {
      this.allowProductFormSelection = false;
      this.productForm = _.find(this.getProducts, ['id', this.$route.params.productId]);
    }
  }
};
</script>

<style scoped/>
